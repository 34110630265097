@use "@shared/ui/styles/breakpoints";

.locked-application-modal {
  --modal-max-width: var(--size-xs);
  --modal-max-height: var(--size-sm);
  --modal-position-left: calc(50% - var(--modal-max-width) / 2);
  --modal-padding-x: var(--spacing-6);
  --modal-padding-y: var(--spacing-5) var(--spacing-6);
  gap: var(--spacing-5);

  @include breakpoints.md {
    --modal-max-width: var(--size-content-3);
    width: var(--size-content-3);
  }
}

.modal-header {
  border-bottom: var(--border-width-1) solid var(--color-gray-300);
  padding-bottom: var(--spacing-2);
  margin-bottom: var(--spacing-2);

  &-heading {
    line-height: var(--line-height-2);
  }
}

.modal-body {
  &__text {
    display: block;
    margin-bottom: var(--spacing-4);
  }
}

.contact {
  width: max-content;

  &-item:not(:last-child) {
    margin-bottom: var(--spacing-3);
  }
}
