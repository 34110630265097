.action-bar-mobile {
  background-color: var(--color-background-subtle);
  padding-inline: var(--spacing-5);

  &--small {
    position: relative;
    z-index: var(--layer-4);
  }
}

.back-button {
  --button-icon-size: auto;
  background-color: black;
}

.external {
  padding-bottom: var(--spacing-4);

  & [id="address"] {
    font-weight: var(--font-weight-4);
  }
}
