.progress-bar {
  background-color: var(--color-gray-300);
  height: 4px;
  width: 100%;
  border-radius: var(--border-radius-5);

  &--completed {
    height: 4px;
    background-color: var(--color-secondary-a-500);
  }
}
