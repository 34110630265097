.communication-preferences {
  padding-top: var(--spacing-4);

  &__grid {
    grid-template-columns: auto 65px 65px;
  }

  &__method {
    padding: var(--spacing-2);
  }

  &__type {
    padding: var(--spacing-2);
  }

  &__note {
    padding: 0 var(--spacing-2) var(--spacing-2);
  }
}

.checkbox {
  &-wrapper {
    justify-content: center;
  }
}
