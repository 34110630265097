.logout {
  box-shadow: none;
  background-color: var(--color-primary-b-300);
  border-radius: var(--border-radius-3);
  margin: var(--spacing-4) var(--spacing-5);

  &:hover {
    box-shadow: none;
  }

  &__content {
    min-height: 250px;
  }

  &__header {
    color: var(--color-brand);
    text-align: center;
    max-inline-size: 300px;
  }
}
