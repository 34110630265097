@use "@shared/ui/styles/breakpoints";

.user-settings-modal {
  --modal-width: min(calc(90vw), 600px);
  --modal-position-left: calc(50vw - (var(--modal-width)) / 2);
  height: calc(var(--size-13) * 2.5);

  @include breakpoints.md {
    --modal-position-top: 100px;
    --modal-width: min(calc(80vw), 600px);
  }
}

.tabs {
  overflow: hidden;
}

.form {
  height: 100%;
}

.header {
  font-size: var(--font-size-4);

  @include breakpoints.md {
    font-size: var(--font-size-5);
  }
}

.title {
  font-size: var(--font-size-1);
}

.input {
  &--name {
    font-size: var(--font-size-0);
    font-weight: var(--font-weight-5);
  }

  &--value {
    font-size: var(--font-size-1);
    font-weight: var(--font-weight-7);
  }
}

.tab-panel {
  flex-direction: column;
  gap: var(--spacing-5);
  padding-left: 0;
  padding-right: 0;
  width: auto;
  flex-grow: 1;
}

.button {
  margin-left: auto;
  width: fit-content;
}

.ctas {
  gap: var(--spacing-3);
  padding-top: var(--spacing-5);
}

.footer {
  border-top: var(--border-width-1) solid var(--color-background-subtle);
}
