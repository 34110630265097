.delete-page {
  padding-top: var(--spacing-7);

  &--mobile {
    padding-top: 0;
    margin-block: auto;
    min-height: calc(100dvh - var(--size-9));
    height: 100%;
  }
}
.confirmation-wrapper {
  max-width: var(--size-md);
  padding: var(--spacing-5);
  background-color: white;
  border-radius: var(--border-radius-2);
}
