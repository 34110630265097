@use "@shared/ui/styles/breakpoints";

.unauthorized {
  display: grid;
  place-content: center;
  min-height: 100dvh;
  max-width: 100dvw;
  color: var(--color-brand);

  &--with-sidebar {
    max-width: calc(100dvw - 48px);
  }

  @include breakpoints.at("md") {
    max-width: 100dvw;
    width: 100%;
  }

  &__content {
    margin: var(--spacing-6);
    text-align: center;
  }

  .icon {
    color: var(--color-brand);
  }

  &__title {
    font-size: var(--font-size-h6);
    font-weight: var(--font-weight-6);

    @include breakpoints.at("md") {
      font-size: var(--font-size-h4);
    }
  }
}
