@use "@shared/ui/styles/breakpoints";

.delete-application-modal {
  --modal-width: min(calc(90vw), 600px);

  min-height: 23rem;

  &__header {
    justify-content: flex-end;
  }

  &__footer {
    margin-top: var(--spacing-2);
  }

  &__ctas {
    flex-direction: column-reverse;

    @include breakpoints.sm {
      justify-content: flex-end;
      flex-direction: row;
    }
  }
}

.underline {
  text-decoration: underline;
}
