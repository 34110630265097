@use "@shared/ui/styles/breakpoints";

.modal {
  padding-bottom: var(--spacing-7);
  padding-inline: var(--spacing-6);

  &-description {
    font-size: var(--font-size-0);
  }
  &-buttons-wrapper {
    padding-bottom: var(--spacing-3);
  }

  &-button {
    width: 100%;
    height: 3.5rem;
  }
}

.close-button {
  color: black;
  padding: 0;
  align-self: flex-start;
  height: var(--size-7);

  @include breakpoints.md {
    display: none;
  }
}
