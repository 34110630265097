@use "@shared/ui/styles/breakpoints";

.step {
  padding: var(--spacing-1);
  background-color: white;
  border-radius: var(--border-radius-2);
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;

  @include breakpoints.md {
    padding: var(--spacing-3);
  }

  &__status-container {
    width: 100%;
    align-items: center;

    @include breakpoints.md {
      align-items: flex-start;
    }
  }

  &__status {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    @include breakpoints.md {
      flex-direction: column;
      align-items: flex-start;
    }

    &-modal {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &-expand {
    opacity: 0;
    margin-left: var(--spacing-2);

    @include breakpoints.md {
      margin-left: 0;
      display: none;
    }
  }

  &:hover {
    .step-expand {
      opacity: 1;
      @include breakpoints.md {
        display: block;
      }
    }
  }
}

.chip {
  &-fr {
    width: var(--size-10);
  }

  &-en {
    width: var(--size-9);
  }

  &-fr,
  &-en {
    display: flex;
    justify-content: center;

    @include breakpoints.md {
      width: auto;
    }
  }

  &-modal {
    height: var(--size-4);
    min-height: var(--size-4);
  }

  &-complete {
    background-color: var(--color-status-success-foreground-saturated);
    color: white;
  }
  &-active {
    background-color: var(--color-secondary-b-600);
    color: white;
  }
  &-pending {
    background-color: var(--color-gray-300);
  }
}

.modal-step {
  background-color: white;
  border-radius: var(--border-radius-2);
  padding: var(--spacing-3);
}
