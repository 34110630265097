@use "@shared/ui/styles/breakpoints";

.action-bar {
  padding-inline: var(--size-5);
  padding-block: var(--spacing-5);
  align-items: center;
  justify-content: space-between;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: var(--layer-3);
  transition: 0.1s;
  gap: var(--spacing-4);

  @include breakpoints.md {
    padding-inline: var(--size-9);
    padding-block: var(--spacing-4) var(--spacing-3);
    top: var(--size-10);
    position: sticky;
    height: 6.5rem;
  }

  &-narrow {
    padding-block: var(--spacing-3);
  }

  & + form {
    .reachable__header {
      &:not([aria-disabled]) {
        top: calc(24px + var(--size-11));
      }
    }
  }
}
