@use "@shared/ui/styles/breakpoints";

.breadcrumbs {
  width: 100%;

  @include breakpoints.md {
    width: auto;
  }

  &__item-container {
    --flex-gap: var(--spacing-2);

    @include breakpoints.md {
      --flex-gap: var(--spacing-5);
    }
  }

  &__item-container-mobile {
    grid-template-columns: auto 1fr;
  }

  &__item {
    font-size: var(--font-size-0);
    column-gap: var(--spacing-2);

    @include breakpoints.md {
      font-size: var(--font-size-1);
      column-gap: var(--spacing-2);
    }

    @include breakpoints.lg {
      font-size: var(--font-size-3);
      font-weight: var(--font-weight-7);
      column-gap: var(--spacing-5);
    }
  }

  &__chevron {
    flex-shrink: 0;
  }
}

.entity-selector {
  width: 100%;
  grid-column: span 2;

  & > div {
    width: 100%;
  }

  @include breakpoints.md {
    width: auto;
    flex-grow: 0;
    grid-column: unset;
  }

  &__chevron {
    display: none;

    @include breakpoints.md {
      display: block;
    }
  }
}
