.down-payment {
  background: none;

  &__container {
    padding-right: var(--spacing-4);
  }
}

.icon {
  flex-shrink: 0;
}
