@use "@shared/ui/styles/breakpoints";

.home {
  font-size: var(--font-size-1);
  font-weight: var(--font-weight-7);
  min-height: calc(100dvh - var(--size-10));
  height: 100%;
  background-color: var(--color-background-muted);

  @include breakpoints.md {
    width: min(90%, var(--size-xl));
    height: auto;
    margin: 0 auto;
    padding-block: var(--spacing-5) var(--spacing-7);
  }

  &-wrapper-header {
    width: 100%;
    position: sticky;
    top: var(--size-9);
    background-color: white;
    z-index: var(--layer-1);

    @include breakpoints.md {
      font-size: var(--font-size-1);
      background-color: var(--color-background-muted);
    }
  }

  &-header {
    padding-block: var(--spacing-5);
    background-color: white;
    width: 100%;
    padding-inline: var(--spacing-5);

    @include breakpoints.md {
      padding-inline: 0;
      text-align: center;
      background-color: var(--color-background-muted);
    }

    &__text {
      font-size: var(--font-size-0);

      @include breakpoints.md {
        font-size: var(--font-size-2);
      }
    }
  }

  &-name {
    color: var(--color-primary-b-500);
  }

  &__applicant {
    width: var(--size-fluid-10);
  }
}

.applications {
  width: 100%;
  align-items: flex-start;
  flex-direction: column;
  padding-left: var(--spacing-5);
  padding-top: var(--spacing-7);
  background-color: var(--color-background-muted);

  &:last-of-type {
    padding-bottom: var(--spacing-5);
  }

  @include breakpoints.md {
    padding: 0;
    background-color: var(--color-background-muted);
  }

  &-list {
    width: 100%;
    grid-template-columns: 1fr;

    @include breakpoints.md {
      grid-template-columns: 1fr 1fr;
    }
  }

  &--disabled {
    opacity: 0.5;
  }
}

.add-button {
  font-weight: var(--font-weight-6);
  width: fit-content;
  margin-inline: var(--spacing-5);
  margin-block-end: var(--spacing-2);
}

.new-app-link {
  &:focus {
    outline: none;
  }
}

.create-new-app {
  border: 1px solid var(--color-background-subtle);
  border-radius: var(--border-radius-2);
  padding: var(--spacing-5);
  min-height: 6.7rem;
  width: 75dvw;
  background: var(--color-blue-200);

  @include breakpoints.sm {
    width: 60dvw;
  }

  @include breakpoints.md {
    width: calc(50% - var(--spacing-2));
  }

  &__icon {
    border-radius: var(--border-radius-2);
    background-color: white;
    padding: var(--spacing-3);
    width: 3rem;
    height: 3rem;
    padding: var(--spacing-3);
  }
}

.portal {
  margin-left: var(--spacing-3);
}
