@use "@shared/ui/styles/breakpoints";

.applicant-button {
  border: none;
  font-size: var(--font-size-00);
  font-weight: var(--font-weight-7);
  color: var(--color-midnight);
  background-color: var(--color-gray-300);
  // margin: var(--spacing-5) 0 var(--spacing-3);

  &--selected {
    background-color: var(--color-primary-b-500);
    color: white;

    // override the hover state from the global.scss file
    // of default `a` tags, since this is a `<Button as="a">` component
    &:hover,
    &:focus {
      color: white;
    }
  }
}

.menu {
  margin-top: var(--spacing-2);
  cursor: pointer;
  color: var(--color-brand);
  font-size: var(--font-size-0);
  font-weight: var(--font-weight-4);

  & a,
  & a:hover,
  & a:focus,
  & a:active,
  & a:visited {
    color: var(--color-brand);
  }

  &-item {
    padding: var(--spacing-3);

    &--selected {
      background-color: var(--color-gray-200);
      border: var(--border-width-1) solid var(--color-primary-b-500);
    }

    &--first {
      border-top-left-radius: var(--menu-border-radius);
      border-top-right-radius: var(--menu-border-radius);
    }
    &--last {
      border-bottom-left-radius: var(--menu-border-radius);
      border-bottom-right-radius: var(--menu-border-radius);
    }
  }

  &-footer {
    padding: var(--spacing-3);
    align-items: center;
  }
}

.button {
  border: var(--border-width-1) solid var(--color-gray-300);
  border-radius: var(--border-radius-4);
  background-color: var(--color-gray-200);
  padding-inline: var(--spacing-3);
  padding-block: var(--spacing-2);
  font-size: var(--font-size-00);
  font-weight: var(--font-weight-7);
  align-items: center;
  justify-content: space-between;
  color: var(--color-brand);
  cursor: pointer;

  @include breakpoints.md {
    border-radius: var(--border-radius-2);
    background-color: white;
    font-size: var(--font-size-0);
    font-weight: var(--font-weight-4);
    max-width: var(--size-14);
  }

  &-name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.tabs {
  --indicator-thickness: 2px;

  &__container {
    background-color: var(--color-background-subtle);
    border-bottom-width: var(--indicator-thickness);
    border-bottom-style: solid;
    border-bottom-color: var(--color-gray-300);
  }

  &__wrapper {
    margin: 0 auto;
    padding-inline: var(--size-5);
    min-width: max-content;

    @include breakpoints.md {
      padding-inline: var(--size-9);
      background-color: white;
    }
  }

  &__list {
    display: flex;
    justify-content: flex-start;
    min-width: max-content;

    a.tab {
      display: inline-flex;
      gap: var(--spacing-2);
      padding-inline: var(--spacing-3);
      padding-block: var(--spacing-3);

      border-bottom-width: var(--indicator-thickness);
      border-bottom-style: solid;
      border-color: transparent;
      background-color: transparent;

      &:hover,
      &:active,
      &.active {
        color: var(--color-primary-b-500);
        border-bottom-color: var(--color-primary-b-500);
      }
    }
  }
}
