.modal {
  height: 100dvh;

  &__description {
    padding: var(--spacing-3);
  }

  &__text {
    font-size: var(--font-size-0);
    font-weight: var(--font-weight-5);
  }
}

.body {
  padding: var(--spacing-4);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);
}

.lender-button {
  text-decoration: underline;
  color: var(--color-tomato-600);
  padding: 0;
  display: inline-flex;
}
