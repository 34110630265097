.tracker-wrapper {
  background-color: var(--color-background-subtle);
  padding: var(--spacing-4) var(--spacing-5);
}

.tracker {
  border: var(--border-width-1) solid var(--color-blueberry-500);
  border-radius: var(--border-radius-2);
  padding: var(--spacing-4);
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-background-muted);

  &-name {
    font-size: var(--font-size-00);
  }
}
