@use "@shared/ui/styles/breakpoints";

.remove-co-applicant-modal {
  --modal-max-width: var(--size-sm);
  --modal-width: min(90vw, 600px);
  --modal-position-left: calc(50vw - (var(--modal-width)) / 2);

  @include breakpoints.md {
    --modal-max-width: var(--size-md);
  }

  &__header {
    justify-content: flex-end;
  }

  &__applicant {
    background: var(--color-foreground-subtle);
    padding: var(--spacing-5) var(--spacing-4);
    border-radius: var(--border-radius-2);

    &:hover {
      cursor: pointer;
    }

    @include breakpoints.md {
      padding: var(--spacing-4);
    }

    &--success {
      background: var(--color-status-success-background);
    }

    &-text {
      flex-grow: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 15ch;
    }

    &-icon {
      background: var(--color-blue-200);
      width: var(--size-7);
      height: var(--size-7);
      border-radius: var(--border-radius-5);
      flex-shrink: 0;
    }
  }
}

.close-toast-icon {
  flex-shrink: 0;
}

.back-button {
  padding: var(--spacing-2);
  margin-left: calc(var(--spacing-2) * -1);
  color: black;
  margin-bottom: var(--spacing-4);
}
