.action-bar-wrapper-mobile {
  padding-inline: var(--size-5);
  padding-block: var(--size-3);
  align-items: center;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: var(--layer-2);
  transition: 0.1s;
  gap: var(--spacing-4);
}
