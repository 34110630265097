.select-application-card {
  &--footer {
    flex-direction: column;
    text-align: center;

    &-cta {
      margin-bottom: var(--spacing-1);
    }
  }
}
