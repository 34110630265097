.layout {
  min-height: 100dvh;

  &--mobile {
    flex-direction: column;
  }

  .secondary-sidebar {
    flex-shrink: 0;
    height: 100vh;
  }

  .section {
    flex: 1 1 auto;
    background-color: var(--color-gray-000);
  }
}
