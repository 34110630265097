@use "@shared/ui/styles/breakpoints";

.banner {
  border-radius: 0;
}

.width-wrapper {
  margin-inline: auto;

  @include breakpoints.lg {
    width: min(var(--size-lg), 100%);
  }

  @include breakpoints.xl {
    width: min(var(--size-xl), 100%);
  }

  & div {
    @include breakpoints.md {
      border-radius: var(--border-radius-2);
    }
  }
}

.main-wrapper {
  background-color: white;
  padding-block: var(--spacing-3) 0;
  padding-inline: var(--spacing-6);
}
