.change-application {
  &__back-button,
  &__title {
    padding: var(--spacing-2);
    text-transform: capitalize;
  }

  &__title {
    margin-bottom: var(--spacing-4);
  }

  &__back-button {
    color: black;
  }
}
