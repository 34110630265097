@use "@shared/ui/styles/breakpoints";

.privacy-phone-footer {
  margin-bottom: 5.5rem;
  padding: 0 var(--spacing-4);
  text-align: center;

  @include breakpoints.md {
    margin-bottom: var(--size-10);
  }

  &__link {
    color: var(--color-primary-b-500);
  }
}
