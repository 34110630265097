@use "@shared/ui/styles/breakpoints";

.modal {
  @include breakpoints.sm {
    --modal-width: min(calc(90vw), 600px);
    --modal-position-left: calc(50% - var(--modal-width) / 2);
  }

  &__header {
    justify-content: flex-end;
  }

  &__content-wrapper {
    padding: var(--spacing-5);
    border: 1px solid var(--color-gray-300);
    border-radius: var(--border-radius-2);
  }
}

.change-application-button {
  padding-inline: var(--spacing-6);
  cursor: pointer;
  justify-content: space-between;
  text-align: left;
  color: black;
  height: auto;
}
