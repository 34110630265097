.choice {
  &__item {
    &--grow label {
      flex-grow: 1;
    }
  }

  &__option {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;

    .truncate {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    padding-inline: var(--spacing-4);
    padding-block: var(--spacing-5);
    border-radius: var(--border-radius-3);

    transition: all 250ms ease-in-out;
    cursor: pointer;

    transform-origin: 50% 50% 50%;
    backface-visibility: hidden;
    font-weight: 400;

    ////////////
    // States //
    ////////////

    // normal / default
    background-color: var(--color-background-default);
    border: 1px solid var(--color-background-disabled);

    // hover
    &:hover:not([data-disabled]),
    &:focus:not([data-disabled]) {
      background-color: var(--color-background-subtle);
      border-color: var(--color-foreground-subtle);
    }

    // active / selected
    &[data-active]:not([data-disabled]),
    &[data-checked]:not([data-disabled]),
    &:active:not([data-disabled]) {
      font-weight: 600;
      background-color: var(--color-tertiary-a-200);
      border-color: var(--color-tertiary-a-500);

      &:hover {
        background-color: var(--color-tertiary-a-300);
        border-color: var(--color-tertiary-a-500);
      }
    }

    // disabled
    &[data-disabled] {
      cursor: default;
      user-select: none;
      opacity: 0.5;
    }
  }
}
