.progress {
  height: 100dvh;
  background-color: white;
  padding: var(--spacing-5);

  &--desktop {
    height: max-content;
    max-width: var(--size-xs);
    border-radius: var(--border-radius-2);
  }

  &--closed {
    height: auto;
    max-width: var(--size-xxs);
  }

  &__header {
    padding-block: var(--spacing-4);
    font-weight: var(--font-weight-7);
  }
}
