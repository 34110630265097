.tracker {
  border: var(--border-width-1) solid var(--color-blueberry-500);
  border-radius: var(--border-radius-3);
  padding-block: var(--spacing-2);
  padding-inline: var(--spacing-3);
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-background-muted);

  &__closed {
    align-self: flex-start;
    font-size: var(--font-size-0);
    &--name {
      font-size: var(--font-size-00);
    }
  }
}
