.wrapper {
  padding: var(--spacing-5);
}

.account-deleted {
  padding: var(--spacing-5);

  &__header {
    line-height: var(--line-height-3);
  }

  &__message {
    font-size: var(--font-size-0);
    font-weight: var(--font-weight-5);
    line-height: var(--line-height-1);

    &--bold {
      font-weight: var(--font-weight-7);
    }
  }

  &__button {
    text-align: center;
    width: fit-content;
    margin: 0 auto;
  }
}
