.sidebar {
  --sidebar-width: 270px;
  --sidebar-min-height: auto;
  --transition-duration: 0;
  --header-padding: var(--spacing-3) var(--spacing-5);
  --header-narrow-padding: var(--spacing-3) var(--spacing-2);

  height: 100vh;
  flex-shrink: 0;
  z-index: var(--layer-4);

  .header {
    min-height: 3.5rem;
    justify-content: center;

    .logo {
      max-height: 3.5rem;
      object-fit: scale-down;
      object-position: center left;
    }
  }

  .footer {
    .initials-avatar {
      width: 35px;
      height: 35px;
      padding: var(--spacing-1);
      border-radius: 50%;
      background-color: var(--color-yellow-500);
      cursor: pointer;
    }

    .name-block {
      background-color: var(--color-background-muted);
      border-radius: var(--border-radius-2);
      padding: var(--spacing-2);

      &__closed {
        background-color: transparent;
        padding: 0;
      }

      .menu {
        padding: var(--spacing-2);
        bottom: var(--spacing-7);

        &__item {
          padding: var(--spacing-2);
          font-size: var(--font-size-0);
          font-weight: var(--font-weight-5);
        }
      }
    }

    .logout {
      padding-inline: 0;
      font-size: var(--font-size-0);
      font-weight: var(--font-weight-5);
    }
    .expand {
      background-color: var(--color-gray-200);
      width: var(--size-7);
      height: var(--size-7);
      border-radius: var(--border-radius-2);
      color: var(--color-black);
      outline: none;
      &:hover {
        color: var(--color-secondary-b-500);
        svg path {
          fill: var(--color-secondary-b-500);
        }
      }
    }
  }
}
