@use "@shared/ui/styles/breakpoints";

.progress-modal {
  --modal-width: min(calc(90vw), 600px);
  --modal-position-left: calc(50% - var(--modal-width) / 2);
  padding: 0;
  --modal-position-top: 50px;

  @include breakpoints.md {
    --modal-max-width: var(--size-md);
    --modal-width: min(calc(90vw), var(--size-md));
    --modal-position-top: calc(50% - var(--modal-max-height) / 2);
  }

  &-text {
    font-size: var(--font-size-0);
    font-weight: var(--font-weight-5);
  }

  &-header {
    padding-inline: var(--spacing-6);
    padding-block: var(--spacing-5);
    align-items: flex-start;
    margin-bottom: 0;
    & > button {
      color: var(--color-foreground-default);
      height: auto;
      min-width: var(--size-5);
    }

    &-name {
      font-size: var(--font-size-4);
      font-weight: var(--font-weight-7);

      @include breakpoints.md {
        font-size: var(--font-size-5);
      }
    }
  }

  &-description-heading {
    font-size: var(--font-size-00);
    font-weight: var(--font-weight-7);
  }

  &-body {
    background-color: var(--color-background-muted);
    padding-inline: var(--spacing-6);
    padding-block: var(--spacing-6);
    border-bottom-left-radius: var(--border-radius-2);
    border-bottom-right-radius: var(--border-radius-2);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-6);

    &-stage {
      padding-bottom: var(--spacing-4);
      align-items: center;
    }

    &-button {
      height: 3.5rem;
      width: fit-content;
    }
  }
}

.btn {
  border: none;
}
