@use "@shared/ui/styles/breakpoints";

.modal {
  padding-inline: var(--spacing-5);
  gap: var(--spacing-6);

  @include breakpoints.md {
    --modal-max-width: var(--size-md);
    width: var(--size-content-3);
  }

  &__header {
    justify-content: end;
  }

  .buttons {
    flex-direction: column;

    @include breakpoints.md {
      flex-direction: row-reverse;
      justify-items: end;
    }
  }

  .button {
    &:focus {
      outline: none;
    }
  }
}
