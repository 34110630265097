@use "@shared/ui/styles/breakpoints";

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-2);
  background-color: var(--color-white);
  border-radius: var(--border-radius-2);
  text-align: center;
  padding-inline: var(--spacing-4);
  padding-block: var(--spacing-7);

  @media (max-width: 768px) {
    width: 100%;
  }

  color: var(--color-foreground-default);
  border: 1px solid transparent;

  &:hover,
  &:focus {
    border-color: var(--color-tertiary-a-500);
    background-color: var(--color-tertiary-a-200);
  }

  &-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    width: 100%;
    gap: var(--spacing-5);
    justify-content: center;
  }

  &-title {
    font-size: var(--font-size-1);

    @include breakpoints.md {
      font-size: var(--font-size-3);
    }
  }

  &-icon {
    width: var(--size-7);
    height: var(--size-7);

    @include breakpoints.md {
      width: var(--size-8);
      height: var(--size-8);
    }
  }
}
