@use "@shared/ui/styles/breakpoints";

.card {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);
  background-color: var(--color-white);
  border-radius: var(--border-radius-2);

  padding-block: var(--spacing-5);
  padding-inline: var(--spacing-5);

  @include breakpoints.at("md") {
    padding-block: var(--spacing-6);
    padding-inline: var(--spacing-6);
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: var(--spacing-2);
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-3);
  }

  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: var(--spacing-2);
  }
}
