.idle-modal {
  &__body {
    border-bottom: var(--border-width-1) solid var(--color-gray-300);
  }

  &__text {
    display: block;
    padding-bottom: var(--spacing-5);
  }

  &__footer {
    display: flex;
    margin-top: var(--spacing-3);
    justify-content: flex-end;
  }
}
