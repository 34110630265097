@use "@shared/ui/styles/breakpoints";

body {
  overflow-x: hidden;
  background: var(--color-background-muted);
}

// Home page / application selection page has a different background color
// we need to override the body background color and make sure the main tag fills the entire viewport
main {
  min-width: 100dvw;
  min-height: 100dvh;
}

.bg {
  &-default {
    background: var(--color-background-default);
  }

  &-default-muted {
    background: var(--color-background-default);

    @include breakpoints.md {
      background: var(--color-background-muted);
    }
  }
}

.content {
  margin: 0 auto;
  width: min(90%, var(--size-xl));
  padding-block: var(--spacing-7);
  min-height: calc(100dvh - var(--size-10));

  &-header {
    padding-bottom: var(--spacing-5);
  }
}

a {
  text-decoration: none;

  // since we are defined colors in the root,
  // we need to make sure to not override the color of the `Button` component
  // data-testid added to avoid the a:hover to override
  // the color of the navigationLink:hover and navigationButton:hover
  &:not(
      [class^="_button"],
      [data-linkbutton],
      [data-testid="navigation-link"],
      [data-testid="navigation-button"],
      [data-testid="button"]
    ) {
    // force the cursor to be pointer
    // example an anchor without href define the cursor doesn't change
    // when we have an anchor with role="button it need to be forced
    cursor: pointer;
    color: var(--color-primary-b-500);

    // underline the anchor in any typography component or p parents
    *[class*="_typography"] &,
    p & {
      text-decoration: underline;
    }

    &:hover,
    &:focus {
      color: var(--color-primary-b-600);
    }
  }

  // on them use the default text color
  &[data-linkbutton] {
    color: var(--color-foreground-default);
  }

  &[target="_blank"] {
    position: relative;
    cursor: pointer;
    text-decoration: underline;
    color: var(--color-primary-b-500);
  }
  // Target blank anchor accessibility
  &[target="_blank"]:after {
    content: "(opens in a new window)";
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
  // Add external svg icon to target blank anchor
  &[target="_blank"]:not(.noicon) {
    padding-right: 0.8rem;
    &:before {
      width: 0.75rem;
      height: 0.75rem;
      content: url('data:image/svg+xml; utf8, <svg class="external-icon" height="0.75rem" width="0.75rem" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m13 3 3.293 3.293-7 7 1.414 1.414 7-7L21 11V3z"></path><path d="M19 19H5V5h7l-2-2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2v-5l-2-2v7z"></path></svg>');
      position: absolute;
      right: 0;
    }
  }
}

input[type="checkbox"] {
  --checkbox-size: var(--size-3);
  --checkbox-color: var(--color-blue-500);

  display: flex;
  flex-basis: var(--checkbox-size);
  flex-shrink: 0;
  width: var(--checkbox-size);
  height: var(--checkbox-size);
  margin: var(--spacing-0);
  padding: var(--spacing-0);
  accent-color: var(--checkbox-color);
  border-radius: var(--border-radius-1);
  border: var(--border-width-1) solid var(--color-blue-500);

  &:disabled {
    opacity: 0.5;
    background-color: var(--color-white);
  }

  &:checked {
    appearance: auto;
  }
}
