.body {
  background-color: var(--color-background-muted);
  padding-inline: var(--spacing-5);
  padding-block-end: var(--size-10);
  padding-block-start: var(--size-5);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-5);
}

.header {
  padding-inline: var(--spacing-5);
  padding-block: var(--spacing-2);
}

.button {
  background-color: transparent;
}
