@use "@shared/ui/styles/breakpoints";

.card {
  border: 1px solid var(--color-background-subtle);
  border-radius: var(--border-radius-2);
  padding: var(--spacing-5);
  min-height: 6.7rem;
  width: 75dvw;
  background-color: white;
  grid-template:
    "house address icons "
    "house chip icons"
    / auto 1fr auto;

  @include breakpoints.sm {
    width: 60dvw;
  }

  @include breakpoints.md {
    width: 100%;
    grid-template:
      "house address chip "
      "house address icons"
      / auto 1fr auto;
  }

  &--disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &:hover {
    cursor: pointer;
    background-color: var(--color-primary-b-200);
    .card-arrow {
      display: block;
      opacity: 1;
    }

    .card-trash {
      @include breakpoints.md {
        opacity: 1;

        display: flex;
        align-self: flex-end;
      }
    }
  }

  &-address {
    font-size: var(--font-size-0);
    font-weight: var(--font-weight-7);
    &-container {
      grid-area: address;
    }
  }

  &-info {
    font-size: var(--font-size-00);
  }

  &-chip {
    grid-area: chip;
    flex-direction: row;
    justify-content: center;
    align-self: flex-start;

    @include breakpoints.md {
      margin: 0;
      flex-direction: column;
    }
  }

  &-icons {
    grid-area: icons;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: column;

    @include breakpoints.md {
      flex-direction: row;
      align-items: flex-end;
      justify-content: flex-end;
    }

    &--edit {
      justify-content: space-between;
      @include breakpoints.md {
        justify-content: flex-end;
      }
    }
  }

  &-house {
    grid-area: house;
  }

  &-arrow {
    opacity: 0;
    margin-bottom: var(--spacing-2);
  }

  &-trash {
    @include breakpoints.md {
      opacity: 0;
      align-self: flex-end;
    }
  }

  &__skeleton-address {
    width: var(--size-fluid-8);
  }

  &__skeleton-info {
    width: var(--size-fluid-7);
  }

  &__skeleton-badge {
    width: var(--size-fluid-5);
    aspect-ratio: var(--ratio-ultrawide);
  }
}
