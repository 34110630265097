@use "@shared/ui/styles/breakpoints";

.reachable {
  --reachable-width: 100%;
  --reachable-header-background-color: var(--color-gray-000);

  width: var(--reachable-width);

  &__item {
    position: relative;

    &__header {
      background-color: var(--reachable-header-background-color);

      &:not([aria-disabled]) {
        position: sticky;
        top: var(--size-9);
        z-index: var(--layer-1);

        @include breakpoints.md {
          top: 0;
        }

        &[data-reached]:not([data-open]) {
          cursor: pointer;
          border-bottom: 1px solid var(--color-gray-400);
        }
      }
    }

    &__content {
      &[hidden] {
        display: none;
      }
    }

    // Unreached item child elements
    &__header,
    &__content {
      &--unreached {
        opacity: 0.32;
        cursor: default;
        outline: none;
        pointer-events: none;
      }
    }

    &__counter {
      font-size: 0.75rem;
    }
  }
}
