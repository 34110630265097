@use "@shared/ui/styles/breakpoints";

.dashboard {
  flex-direction: column;
  align-items: flex-start;

  &-heading {
    font-size: var(--font-size-00);
    font-weight: var(--font-weight-5);
  }

  &-wrapper {
    justify-content: space-between;

    @include breakpoints.md {
      padding-right: var(--spacing-2);
    }
  }

  &-address {
    font-size: var(--font-size-0);
    font-weight: var(--font-weight-7);
    line-height: var(--line-height-2);
    text-align: left;

    @include breakpoints.md {
      font-size: var(--font-size-3);
    }
  }
}

.button {
  cursor: pointer;
}

.mobile-selector-button {
  border-radius: var(--border-radius-3);
  padding: var(--spacing-3);
  gap: var(--spacing-2);
  background-color: white;
  justify-content: space-between;
  height: fit-content;
  width: 100%;
  color: var(--color-foreground-default);

  &:hover {
    box-shadow: none !important;
  }

  &--small {
    position: fixed;
    top: var(--spacing-3);
    left: 50%;
    transform: translateX(-50%);
    padding: var(--spacing-2) var(--spacing-3);
    width: fit-content;
    max-width: 18ch;

    & [id="address"] {
      flex-shrink: 0;
      font-size: var(--font-size-00);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    & div {
      width: 100%;
    }
  }
}

.menu {
  margin-top: var(--spacing-1);
  max-width: 100%;
  width: 100%;

  @include breakpoints.md {
    max-width: max-content;
    width: max-content;
  }

  display: flex;
  flex-direction: column;

  cursor: pointer;
  padding-top: 0.3rem;

  & a,
  & a:hover,
  & a:focus,
  & a:active,
  & a:visited {
    color: var(--color-brand);
  }

  & > :first-child .option {
    border-top-left-radius: var(--border-radius-2);
    border-top-right-radius: var(--border-radius-2);
  }
  & > :last-child .option {
    border-bottom-left-radius: var(--border-radius-2);
    border-bottom-right-radius: var(--border-radius-2);
  }
}

.option {
  flex-direction: column;
  cursor: pointer;

  padding: var(--spacing-3) var(--spacing-2);
  border-radius: var(--border-radius-2);

  @include breakpoints.sm {
    border-radius: 0;
    padding: var(--spacing-2) var(--spacing-4);
  }

  &--selected {
    background-color: var(--color-gray-200);
    border: var(--border-width-1) solid var(--color-primary-b-500);
  }

  &-address {
    font-size: var(--font-size-0);
    font-weight: var(--font-weight-5);
  }

  &-stage {
    font-size: var(--font-size-00);
    font-weight: var(--font-weight-5);
    color: var(--color-primary-b-500);
  }
}
