@use "@shared/ui/styles/breakpoints";

.no-active-applications {
  font-size: var(--font-size-1);
  font-weight: var(--font-weight-7);
  min-height: calc(100dvh - var(--size-10));
  height: 100%;
  background-color: var(--color-background-muted);

  @include breakpoints.md {
    width: min(90%, var(--size-xl));
    height: auto;
    margin: 0 auto;
    padding-block: var(--spacing-5) var(--spacing-7);
  }

  &__wrapper {
    width: 100%;
    position: sticky;
    top: var(--size-9);
    background-color: var(--color-background-muted);
    z-index: var(--layer-1);
  }

  &__header {
    background-color: white;

    @include breakpoints.md {
      background-color: var(--color-background-muted);
    }
  }

  &__content {
    border: 1px solid var(--color-gray-400);
    padding: var(--spacing-4) var(--spacing-5);
    border-radius: var(--border-radius-2);
    background: white;
    margin-inline: var(--spacing-5);
    text-align: center;
  }
}
