.entry {
  --entry-width: var(--size-fluid-9);

  background-color: white;
  width: var(--entry-width);
  padding-inline: var(--size-4) var(--size-7);
  padding-block: var(--size-4);
  border-radius: var(--border-radius-2);
  border: var(--border-width-1) solid white;
  position: relative;
  cursor: pointer;

  &__remove {
    position: absolute;
    top: var(--size-2);
    right: var(--size-2);
    background-color: transparent;
    visibility: hidden;
    pointer-events: none;
  }

  &:hover {
    .entry__remove {
      visibility: visible;
      pointer-events: all;
    }
  }

  &--selected {
    border-color: var(--color-primary-b-500);
  }

  &__info {
    font-size: var(--font-size-0);
    line-height: var(--line-height-0);
  }
}
