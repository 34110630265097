.ScrollAreaRoot {
  --scrollbar-size: 8px;

  width: 100%;
  overflow: hidden;
  border-radius: 4px;
}

.ScrollAreaViewport {
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

.ScrollAreaScrollbar {
  display: flex;
  /* ensures no selection */
  user-select: none;
  /* disable browser handling of all panning and zooming gestures on touch devices */
  touch-action: none;
  padding: 2px;
  background: var(--color-gray-300);
  transition: background 160ms ease-out;
}
.ScrollAreaScrollbar:hover {
  background: var(--color-gray-400);
}
.ScrollAreaScrollbar[data-orientation="vertical"] {
  width: var(--scrollbar-size);
}
.ScrollAreaScrollbar[data-orientation="horizontal"] {
  flex-direction: column;
  height: var(--scrollbar-size);
}

.ScrollAreaThumb {
  flex: 1;
  background: var(--color-gray-500);
  border-radius: var(--scrollbar-size);
  position: relative;
}
/* increase target size for touch devices https://www.w3.org/WAI/WCAG21/Understanding/target-size.html */
.ScrollAreaThumb::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  // keep this 44 x 44 px min size for touch devices. see reference above
  min-width: 44px;
  min-height: 44px;
}

.ScrollAreaCorner {
  background: var(--color-gray-400);
}
