@use "@shared/ui/styles/breakpoints";

.menu {
  min-width: var(--size-13);
  padding: var(--spacing-5);
  border: var(--border-width-1) solid var(--color-gray-200);
  margin-top: var(--spacing-1);

  & a,
  & a:hover,
  & a:focus,
  & a:active,
  & a:visited {
    color: var(--color-brand);
  }

  & > :not(:last-child):not(:first-child) {
    margin-bottom: var(--spacing-3);
  }
}

.contact {
  &-button {
    display: none;

    @include breakpoints.md {
      display: block;
    }
  }

  &-item {
    padding-inline: var(--spacing-2);
    padding-top: var(--spacing-3);
  }
}

.application-dashboard {
  display: block;
}

.button {
  border: none;
}

.header {
  font-size: var(--font-size-1);
  font-weight: var(--font-weight-7);
  margin-bottom: var(--spacing-4);

  &__text {
    margin-top: calc(var(--spacing-2) * -1);
  }
}

.option {
  justify-content: space-between;
  align-items: center;

  &-wrapper {
    padding-inline: var(--spacing-2);
    padding-top: var(--spacing-3);
  }

  &-subheader {
    font-size: var(--font-size-00);
    font-weight: var(--font-weight-5);
  }
  &-data {
    font-size: var(--font-size-0);
    font-weight: var(--font-weight-7);
    max-width: 150px;
    overflow-x: hidden;
    text-overflow: ellipsis;

    @include breakpoints.xs {
      max-width: initial;
      overflow-x: auto;
      text-overflow: initial;
    }
  }
}
