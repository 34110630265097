.progress-modal-advisor {
  justify-content: space-between;
  align-items: start;
  margin-top: var(--spacing-6);
}
.subheader {
  text-transform: capitalize;
}
.btn {
  border: none;
}
