@use "@shared/ui/styles/breakpoints";

.application-selector {
  width: 100%;
  align-items: flex-start;
  flex-direction: column;

  &__list {
    width: 100%;
    grid-auto-flow: column;
    gap: var(--spacing-4);
    padding-bottom: var(--spacing-3);
    padding-right: var(--spacing-5);

    @include breakpoints.md {
      grid-auto-flow: row;
      grid-template-columns: 1fr 1fr;
      padding: 0;
    }
  }
}

.chips-container {
  min-width: max-content;
  align-self: flex-start;
  background-color: white;
  padding: var(--spacing-5);

  @include breakpoints.md {
    padding: 0 0 var(--spacing-5) 0;
    background-color: var(--color-background-muted);
  }

  & a {
    &:last-of-type {
      padding-right: var(--spacing-5);
    }
  }
}
