@use "@shared/ui/styles/breakpoints";

.burger-menu {
  position: fixed;
  width: 100vw;
  min-height: 100vh;
  inset: 0;
  z-index: var(--layer-5);
  background: white;
  overflow-y: scroll;

  @include breakpoints.md {
    overflow-y: visible;
  }

  &__icon {
    justify-content: flex-end;
  }

  &__header {
    height: var(--size-9);
    padding: 0 var(--spacing-3);
    position: sticky;
    z-index: var(--layer-2);
    top: 0;
    background-color: #fff;
    z-index: var(--layer-3);

    // *** NOTE:
    // this work fine for now. IG tenant look great but the nesto logo look too small
    // we should definitely change this to a better solution like having the sizes come from the backend
    // and then we can have a css responsive solution with max width and height to 100% and then we can
    // have a max width and height for the logo <img /> tag
    //
    // https://nestoca.atlassian.net/browse/OG-6143
    //
    img {
      // 25px is the padding of the header
      max-height: calc(var(--size-9) - var(--spacing-3));
      height: 100%;
      width: auto;
      max-width: 100%;
    }

    &.ig {
      max-height: calc(var(--size-10) - 2 * 25px);
    }
  }

  &__content {
    padding: 0 var(--spacing-5) var(--spacing-5);
  }

  // remove open tab icon
  &__link-wrapper::before {
    display: none;
  }

  &__link {
    padding-block: var(--spacing-2);
  }

  &__button {
    width: 50%;
    background: var(--color-background-muted);
    margin-block: 40px var(--spacing-5);
    padding: var(--spacing-5);
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 0;
    text-align: center;
    height: auto;
    color: var(--color-foreground-muted);

    &:hover {
      background-color: var(--color-blue-200);
      border-color: var(--color-blue-200);
    }

    > svg {
      width: auto;
      height: auto;
    }
  }

  &__close-button {
    color: black;
  }

  &--signOut {
    margin-top: var(--spacing-5);
    height: 3.5rem;
  }
}

.language {
  padding-block: var(--spacing-2);

  &-button {
    border: var(--border-width-1) solid var(--color-gray-300);
    border-radius: var(--border-radius-2);
    padding-inline: var(--spacing-4);
    padding-block: var(--spacing-3);
    font-size: var(--font-size-1);
    font-weight: var(--font-weight-4);
    align-items: center;
    max-width: var(--size-14);
    color: var(--color-brand);
    cursor: pointer;
    text-transform: capitalize;

    &-name {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  &-menu {
    margin-top: var(--spacing-2);
    cursor: pointer;
    color: var(--color-brand);
    font-size: var(--font-size-1);
    font-weight: var(--font-weight-4);

    &-item {
      padding-inline: var(--spacing-4);
      padding-block: var(--spacing-3);
      text-transform: capitalize;

      &--selected {
        background-color: var(--color-gray-200);
        border: var(--border-width-1) solid var(--color-primary-b-500);
      }
    }

    & > :first-child > .language-menu-item {
      border-top-left-radius: var(--menu-border-radius);
      border-top-right-radius: var(--menu-border-radius);
    }

    & > :last-child > .language-menu-item {
      border-bottom-left-radius: var(--menu-border-radius);
      border-bottom-right-radius: var(--menu-border-radius);
    }
  }
}

.header {
  margin-block: var(--spacing-3);
}

.back-button {
  margin-left: calc(-1 * var(--spacing-2));
  margin-block: var(--spacing-2);
  gap: var(--spacing-2);
  text-transform: capitalize;
  color: black;
  padding: var(--spacing-2);
}

.settings {
  &__user-info {
    width: 100%;
  }

  &__user-details {
    padding-block: var(--spacing-4);
  }

  &__user-details-item {
    padding-top: var(--spacing-3);
    padding-inline: var(--spacing-2);

    &-edit {
      padding-top: var(--spacing-3);
    }
  }

  &__language {
    margin-top: calc(var(--spacing-1) * -1);
    padding-inline: var(--spacing-2);
    padding-bottom: var(--spacing-4);
  }

  &__language--selected {
    text-transform: capitalize;
  }

  &__buttons {
    margin-top: var(--spacing-5);
  }
}

.edit {
  cursor: pointer;
}

.delete-button {
  height: 3.5rem;
  &__tooltip {
    width: fit-content;
    max-width: 90%;
  }
  &__tooltip-text {
    font-size: var(--font-size-00);
  }
}

.GAQ {
  .burger-menu {
    &__content {
      padding-inline: var(--spacing-5);
    }

    &__link-wrapper {
      padding-block: var(--spacing-4);
    }

    &__cta {
      padding-top: var(--spacing-4);
    }
  }
}
