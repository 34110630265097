@use "@shared/ui/styles/breakpoints";

.card {
  flex-direction: column;
  justify-content: space-between;
  width: 8.5rem;
  height: 8.5rem;
  border-radius: var(--border-radius-3);
  background-color: var(--color-background-muted);
  padding: var(--spacing-3);
  cursor: pointer;
  flex-shrink: 0;

  @include breakpoints.md {
    width: 7.5rem;
    height: 7.5rem;
  }

  @include breakpoints.lg {
    width: 8.5rem;
    height: 8.5rem;
  }

  &:hover {
    background-color: var(--color-primary-b-200);
  }

  &--complete {
    background-color: var(--color-status-success-background);

    &:hover {
      background-color: var(--color-green-300);
    }
  }
  &--next {
    background-color: var(--color-primary-b-200);

    &:hover {
      background-color: var(--color-primary-b-300);
    }
  }

  &:first-child {
    margin-left: var(--spacing-5);

    @include breakpoints.md {
      margin-left: 0;
    }
  }

  &:last-child {
    margin-right: var(--spacing-5);

    @include breakpoints.md {
      margin-right: 0;
    }
  }
}

.title {
  padding-top: var(--spacing-2);

  @include breakpoints.md {
    font-size: 0.75rem;
  }

  @include breakpoints.lg {
    font-size: var(--font-size-0);
  }
}
