@use "@shared/ui/styles/breakpoints";

.header {
  height: var(--size-9);
  padding: 0 var(--spacing-3);
  position: sticky;
  top: 0;
  background-color: var(--color-background-subtle);
  z-index: var(--layer-4);

  // *** NOTE:
  // this work fine for now. IG tenant look great but the nesto logo look too small
  // we should definitely change this to a better solution like having the sizes come from the backend
  // and then we can have a css responsive solution with max width and height to 100% and then we can
  // have a max width and height for the logo <img /> tag
  //
  // https://nestoca.atlassian.net/browse/OG-6143
  //
  img {
    max-height: calc(var(--size-9) - var(--spacing-3));
    height: 100%;
    width: auto;
    max-width: 100%;

    &.ig {
      max-height: calc(var(--size-10) - 2 * 25px);
    }
  }

  @include breakpoints.md {
    height: var(--size-10);
    border-bottom: 2px solid #fafafa;
    padding: 0 25px;
    background-color: #fff;
  }
}

.burger-menu-button {
  color: black;
}

.applicant_menu {
  width: var(--size-7);
  height: var(--size-7);
  padding: unset;
  border-radius: 50%;
  background-color: var(--color-secondary-a-500);
  border-color: var(--color-secondary-a-500);
  color: var(--color-brand);
  font-weight: 700;
  font-size: 14px;

  &:hover:not(:focus, :disabled) {
    background-color: var(--color-secondary-a-500);
    border-color: var(--color-secondary-a-500);
  }
}

.home {
  width: var(--size-5);

  &--link {
    cursor: pointer;
  }
}

.home_btn {
  border-radius: var(--border-radius-2);
  display: flex;
  align-items: center;
  margin: 0 var(--size-3);
  font-size: calc(var(--font-size-0) - 2px);
  font-weight: var(--font-weight-6);
  padding: var(--spacing-2) var(--spacing-4);

  @include breakpoints.md {
    font-size: var(--font-size-1);
    font-weight: var(--font-weight-4);
    padding: var(--spacing-3) var(--spacing-5);
  }
}

.applicant_menu_dropdown {
  display: flex;
  flex-direction: column;
  width: 200px;
  border-radius: var(--border-radius-2);
  color: var(--color-brand);
  background-color: #fff;
  margin-top: var(--spacing-1);

  & div {
    padding: var(--spacing-1);

    &:hover {
      background-color: var(--color-background-subtle);
    }
    &:focus {
      border: var(--border-width-1) solid var(--color-primary-b-500);
    }
  }
}

.applicant_menu_dropdown_item {
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  border: none;
  &:hover {
    background-color: transparent;
    color: var(--color-brand);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  }
}

.language_icon {
  cursor: pointer;
  margin-right: 2;
  align-items: center;
  font-weight: bold;
  height: var(--size-3);
  width: var(--size-3);
}

.dropdown_menu_item {
  display: flex;
  justify-content: flex-start;
  gap: var(--size-2);
  font-weight: var(--font-weight-5);
  border: none;
  background-color: transparent;
  text-align: left;
}

.external_link {
  &[target="_blank"]:before {
    content: none;
  }
}
