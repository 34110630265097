.phase {
  background-color: var(--color-background-muted);
  border-radius: var(--border-radius-2);
  padding: var(--spacing-3);
  justify-content: space-between;
  font-size: var(--font-size-00);
  position: relative;
  transition: 0.3s ease-in-out;

  &:not(:last-child):after {
    content: "";
    background: var(--color-secondary-b-500);
    position: absolute;
    bottom: -1.5rem;
    left: var(--spacing-6);
    height: var(--spacing-5);
    width: var(--border-width-1);
  }

  &--open {
    border: var(--border-width-1) solid var(--color-secondary-b-500);
    padding: var(--spacing-4);
  }

  &__body {
    width: 100%;
    align-items: center;
  }

  &__list {
    width: 100%;
    padding-inline: var(--spacing-2);
  }

  &__header {
    background-color: var(--color-background-muted);
    border-radius: var(--border-radius-2);

    &--modal {
      padding: var(--spacing-2);
    }
  }

  &__text {
    font-size: var(--font-size-00);

    &--open {
      font-size: var(--font-size-0);
    }
  }
  &__name {
    font-size: var(--font-size-1);

    &--open {
      font-size: var(--font-size-2);
    }
  }

  &__button {
    width: fit-content;
    background: white;
  }

  &__status {
    font-size: var(--font-size-0);
    color: var(--color-gray-500);
    font-weight: var(--font-weight-5);

    &--active {
      color: black;
    }
  }
}

.numbers {
  font-size: var(--font-size-3);
  font-weight: var(--font-weight-7);
  &--questionnaire-count {
    color: var(--color-primary-b-500);
  }
  &--documents-count {
    color: var(--color-green-500);
  }
}
