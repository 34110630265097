@use "@shared/ui/styles/breakpoints";

.progress {
  flex-direction: column;
  height: fit-content;
  background-color: var(--color-background-default);
  border-radius: var(--border-radius-2);
  padding: var(--spacing-4);
  width: 100%;
  order: -1;

  &-modal {
    background-color: var(--color-background-muted);
    padding: 0;
    pointer-events: none;
  }

  @include breakpoints.md {
    width: 290px;
    order: initial;
  }

  &-header {
    padding: var(--spacing-2);
    font-size: var(--font-size-1);
    font-weight: var(--font-weight-7);
  }
}
