@use "@shared/ui/styles/breakpoints";

.wrapper {
  @include breakpoints.md {
    --grid-template-columns: 1.2fr 1fr;
    flex-direction: row;
  }
}

.input {
  padding-top: var(--spacing-4);
  padding-bottom: var(--spacing-1);
  padding-inline-start: var(--input-space-for-element);
  & + label {
    margin-inline-start: var(--input-space-for-element);
  }
}
