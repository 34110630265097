@use "@shared/ui/styles/breakpoints";

.loan-partner {
  background-color: var(--color-gray-200);
  border-radius: var(--border-radius-2);
  padding-inline: var(--spacing-4);
  padding-block-end: var(--spacing-4);

  &__title {
    margin-top: var(--spacing-4);

    font-size: var(--font-size-h5);
    font-weight: var(--font-weight-8);

    @include breakpoints.at("md") {
      font-size: var(--font-size-h4);
      font-weight: var(--font-weight-8);
    }
  }

  &__content_1 {
    font-size: var(--font-size-3);
    font-weight: var(--font-weight-5);

    @include breakpoints.at("md") {
      font-size: var(--font-size-4);
      font-weight: var(--font-weight-5);
    }
  }

  &__content_2 {
    font-size: var(--font-size-0);

    @include breakpoints.at("md") {
      font-size: var(--font-size-1);
    }
  }
}
